<template>
  <div class="push-record-detail">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ data.title }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">
          授信等级：{{ data.level }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
        <van-col span="8" @click="to(data.lineDetail)">航线明细
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
        <van-col span="8" @click="to1(data.problermDetail)">成果方案
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
    </div>
    <div>
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="editPlan()">
        <van-field label="计划日期" readonly placeholder="计划日期"
                   v-model="info.plandateStart +'  至  '+ info.plandateEnd"></van-field>
        <van-field label="实际联系人" readonly placeholder="实际联系人"
                   v-model="info.realContact === 'null'?'':info.realContact"></van-field>
        <van-field :required="true" label="业务推进成果" placeholder="请填写业务推进成果"
                   @click-input="clickItem(info.pushResult, '业务推进成果', false)"
                   v-model="info.pushResult === 'null'?'':info.pushResult" label-width="6.5em"></van-field>
        <van-field v-if="info.comOperate" :required="true" label="综合经营情况" placeholder="业务量、应收情况、人员等方面"
                   @click-input="clickItem(info.comOperate, '综合经营情况', false)"
                   v-model="info.comOperate === 'null'?'':info.comOperate" label-width="6.5em"></van-field>
        <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
        <van-field required label="业务量" placeholder="业务量"
                   @click-input="clickItem(info.businessVolume, '业务量', false)"
                   v-model="info.businessVolume === 'null'?'':info.businessVolume" label-width="6.5em" class="lable"></van-field>
        <van-field required label="盈利情况" placeholder="应收情况"
                   @click-input="clickItem(info.profitSituation, '盈利情况', false)"
                   v-model="info.profitSituation === 'null'?'':info.profitSituation" label-width="6.5em" class="lable"></van-field>
        <van-field label="其他" placeholder="人员等方面"
                   @click-input="clickItem(info.others, '其他', false)"
                   v-model="info.others === 'null'?'':info.others" label-width="6.5em" class="lable"></van-field>

        <div v-if="listCf.length>0">
          <van-row>
            <van-col span="16">
              <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
            </van-col>
          </van-row>
          <van-field required v-for="(item,index) in listCf" :label="item.shipName" placeholder="请填写船舶运营情况"
                     @click-input="clickItem(item.shipOperation, item.shipName, true)"
                     v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
        </div>

        <van-field type="textarea" label="遗留问题" readonly placeholder="遗留问题"
                   v-model="info.problerms === 'null'?'':info.problerms"></van-field>
        <van-field type="textarea" label="领导评价" placeholder="领导评价"
                   style="height: 150px"
                   v-model="info.official === 'null'?'':info.official"></van-field>
        <van-button v-if="isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
      <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show" @success="success" @editVal="editVal"></dialog-card>
    </div>
    <crm-tab-bar :tabpage="3"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import DialogCard from "../../components/DigitalCell/DialogCard";

export default {
  name: "BulkCrmsPushReplayDetailEdit",
  components: {CrmTabBar, DialogCard},
  data() {
    return {
      show: false,
      fieldValue: '',
      DialogData: {},
      isHidden: false,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      currentDate: new Date(),
      data: {
        title: '',
        level: '',
        weight: '',
        benefit: '',
        lineDetail: '',
        planDetail: 'BulkCrmsVisitReplayDetail',
        problermDetail: ''
      },
      info: {},
      list: [],
      listCf: [],
      loading: false,
      costomerForm: {
        customerName: [
          {required: true, message: '请选择客户', trigger: 'blur'},
        ],
        target: [
          {required: true, message: '请填写目标', trigger: 'blur'},
        ],
        situation: [
          {required: true, message: '请填写现状', trigger: 'blur'},
        ],
        plan: [
          {required: true, message: '请填写计划方案', trigger: 'blur'},
        ],
        result: [
          {required: true, message: '请填写成功', trigger: 'blur'},
        ],
        problerms: [
          {required: true, message: '请填写遗留问题', trigger: 'blur'},
        ],
        businessVolume: [
          {required: true, message: '', trigger: 'blur'},
        ],
        profitSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        others: [
          {required: true, message: '', trigger: 'blur'},
        ],
        shipOperation: [
          {required: true, message: '', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    to(to) {
      console.log(to)
      if (to.length == 0) {
        this.$message.warning('暂无航线明细！');
      } else {
        this.$router.push(to)
      }
    },
    to1(to) {
      console.log(to)
      if (to.length == 0) {
        this.$message.warning('暂无成果方案！');
      } else {
        this.$router.push(to)
      }
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    onConfirm(time) {
      this.info.date = this.formatter(time);
      this.showPicker = false;
    },
    editPlan(formName) {
      this.$ajax.post('/api/ajax/replay/updateBulkCrmReplayOne.json',
          {
            replayId: this.info.replayId,
            titleId: this.info.titleId,
            official: this.info.official
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('更新成功！');
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsPushReplayEdit/' + this.info.titleId})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('更新失败！');
      })
    },
    init() {
      this.$ajax.post('/api/ajax/replay/getBulkCrmReplayList.json',
          {
            replayId: this.$route.params.id,
            titleId: '',
            titleName: '',
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.info = res.data[0]
            this.data.title = res.data[0].customerName
            this.data.problermDetail = '/RemainProblermsDetail/' + res.data[0].titleId + '/' + res.data[0].crmCustomerid
            this.data.lineDetail = '/LineDetail/' + res.data[0].customerName
            for (let i = 0; i < this.info.length; i++) {
              this.info[i].to = '/BulkCrmsVisitReplayList/' + this.info[i].travelPlanid
            }
            if(res.data[0].bulkCrmCfDetailDTO.length>0){
              this.listCf = res.data[0].bulkCrmCfDetailDTO
            }
          }
          if (this.$store.getters.userId == '05001' || this.$store.getters.userId == '19060') {
            this.isHidden = true
          }

          //获取客户信息
          this.$ajax.post('/api/ajax/bulkCrmsCusList/getHlxy.json',
              {
                customerName: res.data[0].customerName
              }).then(res => {
            if (res.status == 200) {
              if (res.data.length > 0) {
                this.data.weight = res.data[0].hfDTO.weight + "万吨"
                this.data.benefit = res.data[0].hfDTO.profit + "万元"
                this.data.level = res.data[0].customerLevels
              }
              console.log(this.data)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('获取客户货量效益失败！');
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })

    },
    clickItem(val, text, bool){
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },
    success(res){
      this.show = res
    },
    editVal(val){
      if(val.text == '业务推进成果'){
        this.info.pushResult = val.value
      }else if(val.text == '综合经营情况'){
        this.info.comOperate = val.value
      }else if(val.text == '业务量'){
        this.info.businessVolume = val.value
      }else if(val.text == '盈利情况'){
        this.info.profitSituation = val.value
      }else if(val.text == '其他'){
        this.info.others = val.value
      }
    }
  }
}
</script>

<style scoped>

.push-record-detail{
  background-color: #FFFFFF;
}

.push-record-detail .lable{
  margin-left: 15px;
  font-size: 12px;
}

.push-record-detail {
  height: 2500px;
  overflow-y: scroll
}

.push-record-detail .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.push-record-detail .info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.push-record-detail .card {
  background: #318EF5;
}

.push-record-detail .info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}
</style>
